import BlazeSlider from 'blaze-slider'
import "blaze-slider/dist/blaze.css";
import scrollObserver from './intersection.min';

import route from 'ziggy';
const formURL = route('form.submit');
const contactForm = document.getElementById('contactForm');

window.addEventListener('readystatechange', loadAnimations);

function loadAnimations() {
	document.body.classList.add('reveal-hide');
}

contactForm.addEventListener('submit', (event) => {
    event.preventDefault();
    submitForm(event.target);
});

function submitForm(form) {
    let csrf = document.querySelector('meta[name="csrf-token"]')['content'];

    //Select input values with the data you want to send
    let name = document.querySelector('input[name="name"]').value;
    let email = document.querySelector('input[name="email"]').value;
    let company = document.querySelector('input[name="company"]').value;
    let number = document.querySelector('input[name="number"]').value;

    fetch(formURL, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*",
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-TOKEN": csrf
        },
        method: 'post',
        credentials: "same-origin",
        body: JSON.stringify({
            name: name,
            number: number,
            email: email,
            company: company
        })
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            console.log(response.status);
            if (response.status == 500) {
                clearErrors();
                return false;
            }
            return Promise.reject(response); // 2. reject instead of throw
        })
        .then(data => {
            if (data.success === true) {
                form.reset();
                clearErrors();
                formSuccess();
            } else {
                handleFormError();
            }
        })
        .catch((response) => {
            response.json().then((json) => {
                clearErrors();
                handleErrors(json);
            })
        });
}

function formSuccess() {
    const errorP = document.createElement("p");
    errorP.classList.add('error');
    const errorP_node = document.createTextNode('Your details have been submitted, we will be in touch shortly.');
    errorP.append(errorP_node);
    contactForm.append(errorP);
}

function handleFormError() {
    const errorP = document.createElement("p");
    errorP.classList.add('error');
    const errorP_node = document.createTextNode('There was a problem submitting the form, please try again.');
    errorP.append(errorP_node);
    contactForm.append(errorP);
}

function handleErrors(json) {
    if (json.errors) {
        for (const error in json.errors) {
            let inputElement = contactForm.querySelector('[name="' + error + '"]');
            if (inputElement) {
                const errorP = document.createElement("p");
                errorP.classList.add('error');
                const errorP_node = document.createTextNode(json.errors[error]);
                errorP.append(errorP_node);
                inputElement.parentNode.after(errorP);
            }
        }
    }
}

function clearErrors() {
    const allErrors = document.querySelectorAll('.error');
    for (var i = 0; i < allErrors.length; i++) {
        allErrors[i].remove();
        /*allErrors[i].parentNode.classList.remove('error');
        allErrors[i].parentNode.parentNode.querySelector('p.error').remove();*/
    }
}

setTimeout(() => {
	const options = {
		rootMargin : '10px',
		once: true,
	}
	scrollObserver('.reveal', options);
},500);

const headerLinks = document.querySelectorAll('[data-link]');
for (var i = 0; i < headerLinks.length; i++) {
	headerLinks[i].addEventListener('click', (event) => {
		event.preventDefault();
		const targetId = event.target.dataset.link;
		const target = document.getElementById(targetId);
		console.log(target);
		target.scrollIntoView({behavior: "smooth", block: "center"});
	})
}

const banner = document.querySelector('.banner');
const bannerCenter = {
	x: banner.offsetLeft + banner.offsetWidth / 2,
	y: banner.offsetTop + banner.offsetHeight / 2,
}

const elements = {
	main: {
		element: document.querySelector('.layer-main'),
		reverse: true
	},
	white_outline: {
		element: document.querySelector('.layer-white-outline-1'),
	},
	white_outline_2: {
		element: document.querySelector('.layer-white-outline-2'),
		reverse: true
	},
	green_white: {
		element: document.querySelector('.layer-green-white'),
		reverse: true
	},
	orange: {
		element: document.querySelector('.layer-orange'),
	},
}

document.addEventListener("mousemove", parallax);
document.addEventListener('mouseleave', endParallax);

const slider = document.querySelector(".quote-slider");
new BlazeSlider(slider, {
	all: {
		enableAutoplay: true,
		enablePagination: true,
		draggable: true,
		loop: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		transitionDuration: 300,
		autoplayInterval: 7000
	}
});


function parallax(event) {
	let offset = [0,0];
	Object.values(elements).forEach(({element, modifier, reverse = false}) => {
		let mousePosition = {
			x : event.clientX,
			y : event.clientY
		};
		if (reverse) {
			element.style.transform = "translate3d(" + parseInt((((mousePosition.x - bannerCenter.x) / 10) + offset[1])) + 'px, 0px' + ", 0)";
		} else {
			element.style.transform = "translate3d(-" + parseInt((((mousePosition.x - bannerCenter.x) / 10) + offset[1])) + 'px, 0px' + ", 0)";
		}
	});
}

function endParallax() {
	Object.values(elements).forEach(({element}) => {
		element.style.transform = "translate3d(0,0,0)";
	});
}

function getRandomArbitrary(min, max) {
	return Math.random() * (max - min) + min;
}
